import React from 'react'

export const ShrugIcon = React.forwardRef<SVGElement, React.HTMLAttributes<SVGElement>>(
  ({ className }) => {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 995 303.375"
        x="0px"
        y="0px"
      >
        <path d="M2.5,392.77V378.65H133.08v14.12Z" transform="translate(-2.5 -378.65)" />
        <path d="M176.2,589.41,129.69,411h15.87L192,589.41Z" transform="translate(-2.5 -378.65)" />
        <path d="M188.57,621.35V607.12H319.15v14.23Z" transform="translate(-2.5 -378.65)" />
        <path
          d="M369.06,621.35q-16.31-20.58-27.58-48.16a151.15,151.15,0,0,1-2.85-107q9.85-27.69,30.43-55.16h14.12q-13.24,22.77-17.51,32.51A177.56,177.56,0,0,0,355.16,475a183.09,183.09,0,0,0-4.71,41.15q0,52.65,32.73,105.19Z"
          transform="translate(-2.5 -378.65)"
        />
        <path
          d="M426.6,418.39C435.12,435,445,461.87,448.12,478l-15,4.93C430,467.25,420.55,440.58,412,423.09Zm71.06-12.78c5.16,18.61,10.76,46.4,11.88,63.66l-15.91,2.47C492.28,454,487.35,427.13,482,408.3Z"
          transform="translate(-2.5 -378.65)"
        />
        <path
          d="M636.41,621.35H622.29Q655,568.81,655,516.16a179.81,179.81,0,0,0-4.71-40.83,171.74,171.74,0,0,0-10.4-31.52Q635.64,434,622.29,411h14.12q20.58,27.48,30.43,55.16a150.78,150.78,0,0,1-2.9,107Q652.61,600.77,636.41,621.35Z"
          transform="translate(-2.5 -378.65)"
        />
        <path d="M680,621.35V607.12H810.55v14.23Z" transform="translate(-2.5 -378.65)" />
        <path d="M808,589.41,854.55,411h15.76L823.91,589.41Z" transform="translate(-2.5 -378.65)" />
        <path d="M866.92,392.77V378.65H997.5v14.12Z" transform="translate(-2.5 -378.65)" />
        <path
          d="M455.38,584.26,450,568.15l2.71,8.06-2.73-8.05c.22-.08,22.55-7.85,48.61-30.5,24-20.85,56.21-59,73.36-122.35l16.41,4.45C569.93,487.67,535,528.67,509,551.1,480.52,575.64,456.39,583.92,455.38,584.26Z"
          transform="translate(-2.5 -378.65)"
        />
      </svg>
    )
  },
)
